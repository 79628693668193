import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBell, FaGlobe, FaSun, FaMoon, FaUserCircle, FaBars, FaTimes } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import { doSignOut } from '../../auth';
import { ThemeContext } from '../../context/ThemeContext';
import { LanguageContext } from '../../context/LanguageContext'; 
import './MobileNav.css';
import logo_light from '../../assets/brand/Commb 360_final RGB_full color.png';
import logo_dark from '../../assets/brand/Commb 360_final RGB_white red.png';

const MobileNav = () => {
  const { currentUser, isAdmin } = useAuth();
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext);
  const { language, toggleLanguage } = useContext(LanguageContext); 
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleLogout = () => {
    doSignOut()
      .then(() => {
        navigate('/');
      })
      .catch(error => {
        console.error('Error signing out:', error);
      });
  };

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <nav className="mobile-nav">
      <div className="mobile-nav-top">
        <Link to="/">
          <img src={isDarkMode ? logo_dark : logo_light} alt="Logo" />
        </Link>
        <div className="icon" onClick={toggleMenu}>
          {showMenu ? <FaTimes /> : <FaBars />}
        </div>
      </div>

      {showMenu && (
        <div className="mobile-nav-menu">
          <div className="icons">
            <div className="icon" onClick={toggleDarkMode}>
              {isDarkMode ? <FaMoon title="Dark Mode" /> : <FaSun title="Light Mode" />}
            </div>
            {/* <div className="icon" onClick={toggleLanguage}>
              <FaGlobe title={language === 'en' ? 'Language Toggle' : 'Changer la langue'} />
            </div> */}
            <div className="icon">
              <FaBell />
            </div>
          </div>

          <section className="mobile-nav-section">
            <h2>{language === 'en' ? 'Reports' : 'Rapports'}</h2>
            <ul>
              <li><Link to="/">{language === 'en' ? 'Home' : 'Accueil'}</Link></li>
              <li><Link to="/browse">{language === 'en' ? 'Browse Reports' : 'Parcourir les rapports'}</Link></li>
              <li><Link to="/saved">{language === 'en' ? 'Saved Reports' : 'Rapports enregistrés'}</Link></li>
            </ul>
          </section>

          {currentUser && isAdmin && (
            <section className="mobile-nav-section">
              <h2>{language === 'en' ? 'Admin' : 'Administrateur'}</h2>
              <ul>
                <li><Link to="/analytics">{language === 'en' ? 'Analytics' : 'Analytique'}</Link></li>
                <li><Link to="/upload">{language === 'en' ? 'Upload Dashboard' : 'Tableau de téléchargement'}</Link></li>
                <li><Link to="/access">{language === 'en' ? 'Access Requests' : 'Demandes d\'accès'}</Link></li>
                <li><Link to="/invite">{language === 'en' ? 'Generate Invite Codes' : 'Générer des codes d\'invitation'}</Link></li>
                <li><Link to="/viewcodes">{language === 'en' ? 'Manage Invite Codes' : 'Gérer les codes d\'invitation'}</Link></li>
              </ul>
            </section>
          )}

          <section className="mobile-nav-section">
            <h2>{language === 'en' ? 'Account and Support' : 'Compte et Support'}</h2>
            <ul>
              <li><Link to="/settings">{language === 'en' ? 'Settings' : 'Paramètres'}</Link></li>
              <li><Link to="/faqs">{language === 'en' ? 'FAQs' : 'FAQs'}</Link></li>
              <li><Link to="/feedback">{language === 'en' ? 'Feedback' : 'Retour'}</Link></li>
            </ul>
          </section>

          <div className="mobile-nav-bottom">
            {currentUser ? (
              <button onClick={handleLogout} className="logout-button primary-button">
                {language === 'en' ? 'Logout' : 'Se déconnecter'}
              </button>
            ) : (
              <button onClick={handleLoginClick} className="login-button primary-button">
                {language === 'en' ? 'Login' : 'Connexion'}
              </button>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default MobileNav;
