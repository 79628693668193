import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebase';
import { collection, addDoc, serverTimestamp, getDocs, query, orderBy, limit } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './Upload.css';

const Upload = () => {
  const [name, setName] = useState('');
  const [nameFr, setNameFr] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionFr, setDescriptionFr] = useState('');
  const [reportType, setReportType] = useState('');
  const [reportTypeFr, setReportTypeFr] = useState('');
  const [publicationDate, setPublicationDate] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [thumbnail, setThumbnail] = useState('');
  const [imageFileFr, setImageFileFr] = useState(null);
  const [thumbnailFr, setThumbnailFr] = useState('');
  const [iframe, setIframe] = useState('');
  const [iframeFr, setIframeFr] = useState('');
  const [url, setUrl] = useState('');
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [lastReportId, setLastReportId] = useState(0);

  useEffect(() => {
    const fetchLastReportId = async () => {
      try {
        const querySnapshot = await getDocs(query(collection(db, 'dashboard_data'), orderBy('createdAt', 'desc'), limit(1)));
        if (!querySnapshot.empty) {
          const lastDocument = querySnapshot.docs[0];
          const data = lastDocument.data();
          setLastReportId(data.reportId);
        } else {
          setLastReportId(0);
        }
      } catch (error) {
        console.error('Error fetching last reportId:', error);
      }
    };

    fetchLastReportId();
  }, []);

  const handleImageUpload = (e, setImageFileCallback, setThumbnailCallback) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== 'image/png') {
        alert('Please select a PNG file.');
        return;
      }
      if (file.size > 5 * 1024 * 1024) {
        alert('File size exceeds 5MB limit.');
        return;
      }

      const reader = new FileReader();
      reader.onload = function (e) {
        const img = new Image();
        img.onload = function () {
          const maxWidth = 400;
          const aspectRatio = img.naturalWidth / img.naturalHeight;
          const width = Math.min(img.naturalWidth, maxWidth);
          const height = width / aspectRatio;

          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], file.name, {
              type: 'image/png',
              lastModified: Date.now(),
            });

            setThumbnailCallback(URL.createObjectURL(resizedFile));
            setImageFileCallback(resizedFile);
          }, 'image/png');
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      setThumbnailCallback('');
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!name || !description || !reportType || !publicationDate || !iframe || !iframeFr || !url || !nameFr || !descriptionFr || !reportTypeFr || !imageFile || !imageFileFr) {
      setError('All fields are required');
      return;
    }

    setUploading(true);
    setError('');

    try {
      const newReportId = Number(lastReportId) + 1;

      const imageRef = ref(storage, `images/${imageFile.name}`);
      const snapshot = await uploadBytes(imageRef, imageFile);
      const downloadURL = await getDownloadURL(snapshot.ref);

      const imageRefFr = ref(storage, `images/${imageFileFr.name}`);
      const snapshotFr = await uploadBytes(imageRefFr, imageFileFr);
      const downloadURLFr = await getDownloadURL(snapshotFr.ref);

      await addDoc(collection(db, 'dashboard_data'), {
        reportId: newReportId.toString(),
        name,
        name_fr: nameFr,
        description,
        description_fr: descriptionFr,
        reportType,
        reportType_fr: reportTypeFr,
        publicationDate,
        thumbnail: downloadURL,
        thumbnail_fr: downloadURLFr,
        iframe,
        iframe_fr: iframeFr,
        url,
        createdAt: serverTimestamp(),
      });

      setName('');
      setNameFr('');
      setDescription('');
      setDescriptionFr('');
      setReportType('');
      setReportTypeFr('');
      setPublicationDate('');
      setImageFile(null);
      setThumbnail('');
      setImageFileFr(null);
      setThumbnailFr('');
      setIframe('');
      setIframeFr('');
      setUrl('');
      setError('');
      setLastReportId(newReportId);
    } catch (error) {
      console.error('Error uploading data:', error);
      setError('Error uploading data. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="upload container">
      <h2>Upload Dashboard Data</h2>
      <form onSubmit={handleUpload}>
        <div>
          <label>Name (EN)</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Name (FR)</label>
          <input
            type="text"
            value={nameFr}
            onChange={(e) => setNameFr(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description (EN)</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Description (FR)</label>
          <input
            type="text"
            value={descriptionFr}
            onChange={(e) => setDescriptionFr(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Report Type (EN)</label>
          <input
            type="text"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Report Type (FR)</label>
          <input
            type="text"
            value={reportTypeFr}
            onChange={(e) => setReportTypeFr(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Publication Date</label>
          <input
            type="date"
            value={publicationDate}
            onChange={(e) => setPublicationDate(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Image Upload (EN)</label>
          <input
            type="file"
            accept="image/png"
            onChange={(e) => handleImageUpload(e, setImageFile, setThumbnail)}
          />
        </div>
        {thumbnail && <img src={thumbnail} alt="Preview EN" style={{ width: '100px', height: 'auto' }} />}
        <div>
          <label>Image Upload (FR)</label>
          <input
            type="file"
            accept="image/png"
            onChange={(e) => handleImageUpload(e, setImageFileFr, setThumbnailFr)}
          />
        </div>
        {thumbnailFr && <img src={thumbnailFr} alt="Preview FR" style={{ width: '100px', height: 'auto' }} />}
        <div>
          <label>Iframe Embed Code (EN)</label>
          <textarea
            value={iframe}
            onChange={(e) => setIframe(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Iframe Embed Code (FR)</label>
          <textarea
            value={iframeFr}
            onChange={(e) => setIframeFr(e.target.value)}
            required
          />
        </div>
        <div>
          <label>URL</label>
          <input
            type="text"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            required
          />
        </div>
        {error && <span className="error">{error}</span>}
        <button type="submit" className='primary-button' disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
    </div>
  );
};

export default Upload;
