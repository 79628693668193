import React, { useState, useEffect, useRef, useContext } from 'react';
import { FaBell, FaGlobe, FaSun, FaMoon, FaUserCircle, FaSearch } from 'react-icons/fa'; 
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { doSignOut } from '../../auth';
import { ThemeContext } from '../../context/ThemeContext';
import { LanguageContext } from '../../context/LanguageContext'; 
import './Nav.css';
import logo_light from '../../assets/brand/Commb 360_final RGB_full color.png';
import logo_dark from '../../assets/brand/Commb 360_final RGB_white red.png';

const Nav = () => {
  const { currentUser } = useAuth();
  const { isDarkMode, toggleDarkMode } = useContext(ThemeContext); 
  const { language, toggleLanguage } = useContext(LanguageContext); 
  const [searchQuery, setSearchQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSearch = (event) => {
    if ((event.key === 'Enter' || event.type === 'click') && searchQuery.trim()) {
      navigate(`/search?q=${searchQuery.trim()}`);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  
  const handleLogout = () => {
    doSignOut()
      .then(() => {
        navigate('./'); 
      })
      .catch(error => {
        console.error('Error signing out:', error);
      });
  };
  
  const handleLoginClick = () => {
    navigate('/login'); 
  };

  return (
    <nav className="top-nav">
      <div className="logo">
        <Link to="/">
          <img src={isDarkMode ? logo_dark : logo_light} alt="Logo" />
        </Link>
      </div>

      <div className="icons">
        <div className="search-bar">
        <input 
          type="text" 
          placeholder={language === 'en' ? 'Search...' : 'Rechercher...'} 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
          onKeyDown={handleSearch} 
        />
          <FaSearch className="search-icon" onClick={handleSearch} /> 
        </div>

        <div className="icon-menu">
          <div className="icon" onClick={toggleLanguage}>
            <FaGlobe title="Language Toggle" />
          </div>

          <div className="icon" onClick={toggleDarkMode}>
            {isDarkMode ? (
              <FaMoon title="Dark Mode" />
            ) : (
              <FaSun title="Light Mode" />
            )}
          </div>

          {currentUser ? (
            <div className="icon dropdown" onClick={toggleDropdown} ref={dropdownRef}>
              <FaUserCircle title="Account" />
              {showDropdown && (
                <div className="dropdown-content">
                  <Link to="/settings">
                    <p>{language === 'en' ? 'Settings' : 'Paramètres'}</p>
                  </Link>
                  <p onClick={handleLogout}>{language === 'en' ? 'Log out' : 'Se déconnecter'}</p>
                </div>
              )}
            </div>
          ) : (
            <button onClick={handleLoginClick} className="login-button primary-button">
              {language === 'en' ? 'Login' : 'Connexion'}
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Nav;
