import React, { useState, useEffect, useContext } from 'react';
import { updateEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { db } from '../../firebase';
import { doc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { doPasswordChange, doSignInWithEmailAndPassword } from '../../auth';
import { LanguageContext } from '../../context/LanguageContext'; // Import LanguageContext
import './Settings.css';

const Settings = () => {
  const { currentUser, currentUserStatus } = useAuth();
  const { language } = useContext(LanguageContext); // Get the current language
  const [firstName, setFirstName] = useState(currentUserStatus.firstName);
  const [lastName, setLastName] = useState(currentUserStatus.lastName);
  const [email, setEmail] = useState(currentUser.email);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (successMessage || error) {
      const timer = setTimeout(() => {
        setSuccessMessage('');
        setError('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [successMessage, error]);

  const handleSave = async () => {
    try {
      if (!currentUser) {
        setError(language === 'en' ? 'No current user detected.' : 'Aucun utilisateur actuel détecté.');
        console.error(language === 'en' ? 'Error: No current user detected.' : 'Erreur : Aucun utilisateur actuel détecté.');
        return;
      }

      const usersRef = collection(db, 'commb_users');
      const q = query(usersRef, where('uid', '==', currentUser.uid));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error(language === 'en' ? 'No matching user document found' : 'Aucun document utilisateur correspondant trouvé');
      }

      const userDoc = querySnapshot.docs[0];
      const docId = userDoc.id;

      if (firstName !== currentUserStatus.firstName || lastName !== currentUserStatus.lastName) {
        await updateDoc(doc(db, 'commb_users', docId), {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        });
      }

      if (email !== currentUser.email || newPassword) {
        await promptReauthenticate();
      }

      if (email !== currentUser.email) {
        await updateEmail(currentUser, email);
        await updateDoc(doc(db, 'commb_users', docId), { email });
      }

      if (newPassword || confirmNewPassword) {
        if (newPassword !== confirmNewPassword) {
          setError(language === 'en' ? 'New passwords do not match' : 'Les nouveaux mots de passe ne correspondent pas');
          setSuccessMessage('');
          return;
        }
        await doSignInWithEmailAndPassword(currentUser.email, currentPassword);
        await doPasswordChange(newPassword);
      }

      setError('');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setSuccessMessage(language === 'en' ? 'Settings updated successfully!' : 'Paramètres mis à jour avec succès!');
    } catch (error) {
      console.error(language === 'en' ? 'Error during settings update:' : 'Erreur lors de la mise à jour des paramètres :', error);
      setSuccessMessage('');
      setError(language === 'en' ? `Error updating settings: ${error.message}` : `Erreur lors de la mise à jour des paramètres : ${error.message}`);
    }
  };

  const promptReauthenticate = async () => {
    try {
      // Implement your re-authentication logic here
    } catch (error) {
      throw new Error(language === 'en' ? 'Please login again before this action' : 'Veuillez vous reconnecter avant cette action');
    }
  };

  return (
    <div className='container'>
      <div className="setting">
        <h2>{language === 'en' ? 'Settings' : 'Paramètres'}</h2>
        {error && <p className='settings-error'>{error}</p>}
        {successMessage && <p className='settings-success'>{successMessage}</p>}
        
        <div className='settings-form-group'>
          <div className='settings-name-row'>
            <div className='settings-form-group-name'>
              <label>{language === 'en' ? 'First Name:' : 'Prénom:'}</label>
              <input
                type='text'
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div className='settings-form-group-name'>
              <label>{language === 'en' ? 'Last Name:' : 'Nom:'}</label>
              <input
                type='text'
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>
          </div>

          <div className='settings-form-group'>
            <label>{language === 'en' ? 'Current Password:' : 'Mot de passe actuel:'}</label>
            <input
              type='password'
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              name='current-password-field' 
              id='current-password-field'   
              autoComplete='new-password'   
              required                      
            />
          </div>

          <div className='settings-form-group'>
            <label>{language === 'en' ? 'New Password:' : 'Nouveau mot de passe:'}</label>
            <input
              type='password'
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>

          <div className='settings-form-group'>
            <label>{language === 'en' ? 'Confirm New Password:' : 'Confirmez le nouveau mot de passe:'}</label>
            <input
              type='password'
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </div>

          <button className='settings-button primary-button' onClick={handleSave}>
            {language === 'en' ? 'Save Changes' : 'Enregistrer les modifications'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Settings;
