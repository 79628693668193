import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, where, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../firebase';
import './AccessRequests.css';

const AccessRequests = () => {
    const [accessRequests, setAccessRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [ignoredRequests, setIgnoredRequests] = useState([]);

    useEffect(() => {
        const fetchAccessRequests = async () => {
            try {
                const q = query(collection(db, 'commb_users'), 
                                where('accessRequested', '==', true), 
                                where('isVerified', '==', false));
                const querySnapshot = await getDocs(q);
                const requests = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                    verified: false 
                }));
                setAccessRequests(requests);
            } catch (error) {
                console.error('Error fetching access requests:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchAccessRequests();
    }, []);

    const handleUpdateRequest = async (id) => {
        try {
            await updateDoc(doc(db, 'commb_users', id), {
                accessRequested: false,
                isVerified: true
            });
            setAccessRequests(prevRequests =>
                prevRequests.map(request =>
                    request.id === id ? { ...request, verified: true } : request
                )
            );
        } catch (error) {
            console.error('Error updating access request:', error);
        }
    };

    const handleIgnoreRequest = async (id) => {
        try {
            await updateDoc(doc(db, 'commb_users', id), {
                accessRequested: false
            });
            setIgnoredRequests([...ignoredRequests, id]);
        } catch (error) {
            console.error('Error ignoring access request:', error);
        }
    };

    const handleVerifyAll = async () => {
        try {
            const batch = writeBatch(db);

            accessRequests.forEach((request) => {
                const docRef = doc(db, 'commb_users', request.id);
                batch.update(docRef, {
                    accessRequested: false,
                    isVerified: true
                });
            });

            await batch.commit();

            setAccessRequests(prevRequests =>
                prevRequests.map(request => ({
                    ...request,
                    verified: true,
                    accessRequested: false,
                    isVerified: true
                }))
            );

            setIgnoredRequests([]);

        } catch (error) {
            console.error('Error verifying all requests:', error);
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className='container access-requests'>
            <h2>{accessRequests.length === 0 ? 'No pending access requests' : 'Pending Access Requests'}</h2>
            {accessRequests.length > 0 && (
                <>
                    <button onClick={handleVerifyAll} className='primary-button'>Verify All</button>
                    <table>
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Company</th>
                                <th>IP Address</th>
                                <th>Created At</th>
                                <th>UID</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {accessRequests.map((request) => (
                                <tr key={request.id}>
                                    <td>{request.firstName}</td>
                                    <td>{request.lastName}</td>
                                    <td>{request.email}</td>
                                    <td>{request.company}</td>
                                    <td>{request.ipAddress}</td>
                                    <td>{request.createdAt.toDate().toLocaleString()}</td>
                                    <td>{request.uid}</td>
                                    <td>
                                        {request.verified ? (
                                            <button className="verified-button" disabled>Verified</button>
                                        ) : (
                                            ignoredRequests.includes(request.id) ? (
                                                <button className="ignored-button" disabled>Ignored</button>
                                            ) : (
                                                <button className='primary-button' onClick={() => handleUpdateRequest(request.id)}>Verify</button>
                                            )
                                        )}
                                        {!request.verified && !ignoredRequests.includes(request.id) && (
                                            <button onClick={() => handleIgnoreRequest(request.id)}>Ignore</button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
};

export default AccessRequests;
