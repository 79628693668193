import React, { useState, useEffect } from 'react';
import { doSignInWithEmailAndPassword, doPasswordReset } from '../../auth';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { collection, updateDoc, doc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import './LoginModal.css';

const LoginModal = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [accessRequested, setAccessRequested] = useState(false);
    const { currentUser, currentUserStatus } = useAuth();
    const navigate = useNavigate();
    const [countdown, setCountdown] = useState(3);
    const [resetPasswordClicked, setResetPasswordClicked] = useState(false);
    const [registrationCompleted, setRegistrationCompleted] = useState(false);

    // Effect to handle countdown and redirect after registration completes
    useEffect(() => {
        let timer;
        if (registrationCompleted && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            navigate('/');
        }

        return () => clearInterval(timer);
    }, [registrationCompleted, countdown, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isSigningIn) {
            setIsSigningIn(true);
            try {
                await doSignInWithEmailAndPassword(email, password);
                setEmail('');
                setPassword('');
                setErrorMessage('');
                setIsSigningIn(false);
                setShowSuccessMessage(true);
                setRegistrationCompleted(true); // Set registration completed to true after successful login
            } catch (error) {
                if (error.code === 'auth/invalid-credential') {
                    setErrorMessage('The account does not exist or the email or password entered is incorrect.');
                } else {
                    setErrorMessage(error.message);
                }
                setIsSigningIn(false);
            }
        }
    };

    const handleRequestAccess = async () => {
        setAccessRequested(true);
        setShowSuccessMessage(true);

        const usersRef = collection(db, 'commb_users');
        const q = query(usersRef, where('uid', '==', currentUserStatus.uid));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
            await updateDoc(doc.ref, {
                accessRequested: true
            });
        });

        setTimeout(() => {
            navigate('/');
        }, countdown * 1000);
    };
    const handleForgotPassword = async () => {
        if (!email) {
            setErrorMessage('Missing email. Please enter your email address.');
            return;
        }

        try {
            await doPasswordReset(email);
            setErrorMessage('');
            setShowSuccessMessage(true); 
            setResetPasswordClicked(false); 
            setCountdown(3);
        } catch (error) {
            if (error.code === 'auth/invalid-email') {
                setErrorMessage('Invalid email. Please enter a valid email address.');
            } else {
                setErrorMessage('Error sending password reset email: ' + error.message);
            }
        }
    };
    const handleResetPasswordClick = () => {
        setResetPasswordClicked(true);
    };

    return (
        <div className='container login'>
            {!currentUser ? (
                <form onSubmit={handleSubmit}>
                    <div>
                        <input
                            type="email"
                            autoComplete='email'
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Email'
                        />
                    </div>
                    {!resetPasswordClicked && (
                        <div>
                            <input
                                type="password"
                                autoComplete='current-password'
                                required
                                value={password}
                                placeholder='Password'
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                    )}

                    {errorMessage && (
                        <span>{errorMessage}</span>
                    )}

                    {!resetPasswordClicked ? (
                        <button type='submit' className='primary-button' disabled={isSigningIn}>
                            {isSigningIn ? 'Signing In...' : 'Sign In'}
                        </button>
                    ) : (
                        <button type='button' onClick={handleForgotPassword} disabled={isSigningIn}>
                            {isSigningIn ? 'Sending Email...' : 'Send Reset Email'}
                        </button>
                    )}


                    {!resetPasswordClicked && (
                        <p onClick={handleResetPasswordClick} className="forgot-password-link">Forgot your password?</p>
                    )}
                    {resetPasswordClicked && showSuccessMessage && (
                        <p>Password reset email sent. Redirecting to home in {countdown} seconds...</p>
                    )}
                </form>
            ) : (
                <>
                    {showSuccessMessage && currentUserStatus?.isVerified && (
                        <p>Success! Redirecting to home in {countdown} seconds...</p>
                    )}

                    {currentUserStatus && !currentUserStatus.isVerified && (
                        <>
                            <p>Welcome back, {currentUserStatus.firstName}!</p>
                            {!accessRequested && (
                                <>
                                    <p>Please click below to request access before using the dashboard.</p>
                                    <button className='primary-button' onClick={handleRequestAccess}>Request Access</button>
                                </>
                            )}
                            {accessRequested && showSuccessMessage && (
                                <p>You have requested access. Redirecting to home in {countdown} seconds...</p>
                            )}
                        </>
                    )}
                </>
            )}
            {!currentUser && (
                <p>Don't have an account? <Link to="/register"><span>Register</span></Link></p>
            )}
        </div>
    );
};

export default LoginModal;
