import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, deleteDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext'; 
import './ViewCodes.css'

const ViewCodes = () => {
  const [codes, setCodes] = useState([]);
  const { currentUser } = useAuth(); 

  useEffect(() => {
    fetchCodes();
  }, []);

  const fetchCodes = async () => {
    const codesCollectionRef = collection(db, 'invite_codes');
    const q = query(codesCollectionRef);

    try {
      const querySnapshot = await getDocs(q);
      const codesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })).filter(code => !code.role || code.role !== 'admin'); 
      setCodes(codesData);
    } catch (error) {
      console.error('Error fetching codes: ', error);
    }
  };

  const revokeCode = async (codeId) => {
    const codeRef = doc(db, 'invite_codes', codeId);

    try {
      await deleteDoc(codeRef);
      setCodes(prevCodes => prevCodes.filter(code => code.id !== codeId));
    } catch (error) {
      console.error('Error revoking and deleting code: ', error);
    }
  };

  return (
    <div className='container view-codes'>
      <h2>View Codes</h2>
      <table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Max Uses</th>
            <th>Uses</th>
            <th>Expiration Days</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {codes.map(code => (
            <tr key={code.id}>
              <td>{code.code}</td>
              <td>{code.maxUses}</td>
              <td>{code.uses}</td>
              <td>{code.expirationDays === 0 ? 'Never' : code.expirationDays}</td>
              <td>{code.createdBy.firstName} {code.createdBy.lastName}</td>
              <td>
                {!code.revoked && ( 
                  <button onClick={() => revokeCode(code.id)} className='primary-button'>Revoke</button>
                )}
                {code.revoked && <span>Revoked</span>}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewCodes;
