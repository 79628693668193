import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { db } from '../../firebase';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { logDashboardSave } from '../../utils/activityLog';
import './DashboardCard.css';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { LanguageContext } from '../../context/LanguageContext';

const DashboardCard = ({ reportId, name, thumbnail, publicationDate, description, reportType, isPopular, nameEng }) => {
  const navigate = useNavigate();
  const { currentUserStatus } = useAuth();
  const { language } = useContext(LanguageContext); // Get the current language context
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const fetchSavedStatus = async () => {
      if (currentUserStatus && currentUserStatus.isVerified) {
        try {
          const userId = currentUserStatus.uid;
          const savedReportDoc = doc(db, 'commb_users', userId, 'saved_reports', reportId);

          const docSnap = await getDoc(savedReportDoc);

          if (docSnap.exists()) {
            setIsSaved(true);
          } else {
            setIsSaved(false);
          }
        } catch (error) {
          console.error('Error fetching saved status:', error);
        }
      }
    };

    fetchSavedStatus();
  }, [currentUserStatus, reportId]);

  const handleSave = async (event) => {
    event.stopPropagation();
    if (currentUserStatus.isVerified) {
      try {
        const userId = currentUserStatus.uid;
        const savedReportDoc = doc(db, 'commb_users', userId, 'saved_reports', reportId);

        if (!isSaved) {
          await logDashboardSave(userId, reportId);
          await setDoc(savedReportDoc, {});
          setIsSaved(true);
        } else {
          await deleteDoc(savedReportDoc);
          setIsSaved(false);
        }
      } catch (error) {
        console.error('Error saving report:', error);
      }
    } else {
      navigate('/login');
    }
  };

  const handleClick = () => {
    if (currentUserStatus && currentUserStatus.isVerified) {
      navigate(`/dashboard/${nameEng}`);
    } else {
      navigate('/login');
    }
  };

  const isNew = () => {
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    return new Date(publicationDate) > oneMonthAgo;
  };

  return (
    <div className={`dashboard-card ${isSaved ? 'saved' : ''}`} onClick={handleClick}>
      <div className="thumbnail">
        <img src={thumbnail || 'https://placehold.co/300x200/png'} alt={`Thumbnail for ${name}`} />
      </div>
      <Tippy content={language === 'fr' ? 'Enregistrer' : 'Save'} arrow={false} offset={[0, 4]}>
        <FontAwesomeIcon
          icon={faStar}
          className={`star-icon ${isSaved ? 'saved' : ''}`}
          onClick={handleSave}
        />
      </Tippy>
      <div className="details">
        <div className="header">
          <div className="tags">
            {isNew() && <span className="tag new-tag">{language === 'fr' ? 'Nouveau' : 'New'}</span>}
            {isPopular && <span className="tag popular-tag">{language === 'fr' ? 'Populaire' : 'Popular'}</span>}
          </div>
          <div className="top-right">
            <p className="report-type">{reportType}</p>
          </div>
        </div>
        <h3 className="card-title">{name}</h3>
        <p className="publication-date">
          {language === 'fr' ? 'Publié le' : 'Published'}: {publicationDate}
        </p>
        <p className="short-description">{description}</p>
      </div>
    </div>
  );
};

export default DashboardCard;
