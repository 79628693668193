import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.css';
import { db } from '../../firebase';
import { doc, setDoc, deleteDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import { logDashboardSave, logDashboardView } from '../../utils/activityLog';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

const Dashboard = () => {
  const { currentUserStatus } = useAuth();
  const { language } = useContext(LanguageContext); 
  const { name } = useParams(); 
  const [dashboardItem, setDashboardItem] = useState(null);
  const [isSaved, setIsSaved] = useState(false);
  const [hasAccess, setHasAccess] = useState(false); 

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const dashboardRef = collection(db, 'dashboard_data');
        const q = query(dashboardRef, where('name', '==', name.trim()));
        const querySnapshot = await getDocs(q);
        console.log(q);
        
        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          setDashboardItem({ reportId: data.reportId, ...data });
          if (data.status === 'published' || 
              (data.status === 'pending' && (currentUserStatus.role === 'admin' || currentUserStatus.role === 'marketing')) ||
              (!data.status && currentUserStatus.role === 'admin')) {
            setHasAccess(true);
            if (currentUserStatus.uid) {
              logDashboardView(currentUserStatus.uid, data.reportId);
            }
          } else {
            setHasAccess(false); 
          }
        } else {
          console.error('Dashboard not found for name:', name);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();

    return () => {
      console.log('Cleanup for effect');
    };
  }, [name, currentUserStatus]);

  useEffect(() => {
    const fetchSavedStatus = async () => {
      if (currentUserStatus.isVerified && dashboardItem) {
        try {
          const userId = currentUserStatus.uid;
          const userRef = doc(db, 'commb_users', userId);
          const savedReportDoc = doc(userRef, 'saved_reports', dashboardItem.reportId);

          const docSnap = await getDoc(savedReportDoc);
          setIsSaved(docSnap.exists());
        } catch (error) {
          console.error('Error fetching saved status:', error);
        }
      }
    };

    fetchSavedStatus();
  }, [currentUserStatus, dashboardItem]); 

  const handleSave = async (event) => {
    event.stopPropagation();

    try {
      if (!currentUserStatus.isVerified) {
        return;
      }

      const userId = currentUserStatus.uid;
      logDashboardSave(userId, dashboardItem.reportId);

      const userRef = doc(db, 'commb_users', userId);
      const savedReportDoc = doc(userRef, 'saved_reports', dashboardItem.reportId);

      if (!isSaved) {
        await setDoc(savedReportDoc, {});
        setIsSaved(true);
      } else {
        await deleteDoc(savedReportDoc);
        setIsSaved(false);
      }
    } catch (error) {
      console.error('Error saving report:', error);
    }
  };

  if (!dashboardItem) {
    return <div className='dashboard container'>Dashboard not found.</div>;
  }

  if (!hasAccess) {
    return <div className='dashboard container'>You do not have access to view this dashboard.</div>; 
  }

  return (
    <div className="dashboard container">
      <div className="tool-bar">
        <button className={`save-button ${isSaved ? 'saved' : ''}`} onClick={handleSave}>
          <Tippy content='Save' arrow={false} offset={[0, 4]}>
            <FontAwesomeIcon
              icon={faStar}
              className={`star-icon ${isSaved ? 'saved' : ''}`}
            />
          </Tippy>
        </button>
      </div>
      <iframe 
        title={name} 
        src={language === 'fr' && dashboardItem.url_fr ? dashboardItem.url_fr : dashboardItem.url} 
        width="100%" 
        height="100%" 
        frameBorder="0">
      </iframe>
    </div>
  );
};

export default Dashboard;
