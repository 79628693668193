import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage"; 

const firebaseConfig = {
  apiKey: "AIzaSyBxxzPAut1XHx-XnqSCFEkyFLX8NmM_EXk",
  authDomain: "insights-52cf9.firebaseapp.com",
  projectId: "insights-52cf9",
  storageBucket: "insights-52cf9.appspot.com",
  messagingSenderId: "441177686711",
  appId: "1:441177686711:web:b168d1dd8816d1a062c332",
  measurementId: "G-Y41EZJD22Q"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); 

export { app, auth, db, storage }; 
