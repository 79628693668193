// utils/codeGenerator.js

export const generateRandomCode = () => {
  const getRandomSymbol = () => {
    const symbols = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-', '+', '=', '{', '}', '[', ']', '|', '\\', ':', ';', '"', "'", '<', '>', ',', '.', '/', '?'];
    return symbols[Math.floor(Math.random() * symbols.length)];
  };

  const championNames = [
    'Aatrox', 'Ahri', 'Akali', 'Alistar', 'Amumu', 'Anivia', 'Annie', 'Aphelios', 'Ashe',
    'Aurelion Sol', 'Azir', 'Bard', 'Blitzcrank', 'Brand', 'Braum', 'Caitlyn', 'Camille',
    'Cassiopeia', 'Cho\'Gath', 'Corki', 'Darius', 'Diana', 'Dr. Mundo', 'Draven', 'Ekko',
    'Elise', 'Evelynn', 'Ezreal', 'Fiddlesticks', 'Fiora', 'Fizz', 'Galio', 'Gangplank',
    'Garen', 'Gnar', 'Gragas', 'Graves', 'Hecarim', 'Heimerdinger', 'Illaoi', 'Irelia',
    'Ivern', 'Janna', 'Jarvan IV', 'Jax', 'Jayce', 'Jhin', 'Jinx', 'Kai\'Sa', 'Kalista',
    'Karma', 'Karthus', 'Kassadin', 'Katarina', 'Kayle', 'Kayn', 'Kennen', 'Kha\'Zix',
    'Kindred', 'Kled', 'Kog\'Maw', 'LeBlanc', 'Lee Sin', 'Leona', 'Lillia', 'Lissandra',
    'Lucian', 'Lulu', 'Lux', 'Malphite', 'Malzahar', 'Maokai', 'Master Yi', 'Miss Fortune',
    'Mordekaiser', 'Morgana', 'Nami', 'Nasus', 'Nautilus', 'Neeko', 'Nidalee', 'Nocturne',
    'Nunu & Willump', 'Olaf', 'Orianna', 'Ornn', 'Pantheon', 'Poppy', 'Pyke', 'Qiyana',
    'Quinn', 'Rakan', 'Rammus', 'Rek\'Sai', 'Rell', 'Renekton', 'Rengar', 'Riven', 'Rumble',
    'Ryze', 'Samira', 'Sejuani', 'Senna', 'Seraphine', 'Sett', 'Shaco', 'Shen', 'Shyvana',
    'Singed', 'Sion', 'Sivir', 'Skarner', 'Sona', 'Soraka', 'Swain', 'Sylas', 'Syndra',
    'Tahm Kench', 'Taliyah', 'Talon', 'Taric', 'Teemo', 'Thresh', 'Tristana', 'Trundle',
    'Tryndamere', 'Twisted Fate', 'Twitch', 'Udyr', 'Urgot', 'Varus', 'Vayne', 'Veigar',
    'Vel\'Koz', 'Vi', 'Viktor', 'Vladimir', 'Volibear', 'Warwick', 'Wukong', 'Xayah',
    'Xerath', 'Xin Zhao', 'Yasuo', 'Yone', 'Yorick', 'Yuumi', 'Zac', 'Zed', 'Ziggs', 'Zilean',
    'Zoe', 'Zyra'
  ];

  const getRandomSymbolOrNumber = () => {
    return Math.random() < 0.5 ? getRandomSymbol() : Math.floor(Math.random() * 10);
  };

  const randomChampion = championNames[Math.floor(Math.random() * championNames.length)];
  const remainingLength = 30 - randomChampion.length;
  const spacedOutCode = [];

  const lowerCaseChampion = randomChampion.toLowerCase();

  let charCount = 0;

  for (let i = 0; i < lowerCaseChampion.length; i++) {
    const char = lowerCaseChampion.charAt(i);
    spacedOutCode.push(char);
    charCount++;
    if (charCount >= remainingLength) break;

    if (i < lowerCaseChampion.length - 1) {
      spacedOutCode.push(getRandomSymbolOrNumber());
      charCount++;
    }
  }

  while (charCount < 30) {
    spacedOutCode.push(getRandomSymbolOrNumber());
    charCount++;
  }

  const code = spacedOutCode.slice(0, 30).join('');

  return {
    code,
    numUses: 1,
    expirationDays: 0, // Default expiration days set to 0
    maxUses: 1 // Default max uses set to 1
  };
};
