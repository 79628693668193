import React, { useState, useEffect, useContext } from 'react';
import './Browse.css';
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { LanguageContext } from '../../context/LanguageContext';

const Browse = () => {
  const [filterType, setFilterType] = useState('all');
  const [sortOrder, setSortOrder] = useState('asc');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const dashboardCollectionRef = collection(db, 'dashboard_data');
        const snapshot = await getDocs(dashboardCollectionRef);
        const fetchedData = snapshot.docs.map(doc => ({
          reportId: doc.id,
          ...doc.data()
        }));

        setData(fetchedData);
        setFilteredData(fetchedData);

        const uniqueTypes = [
          ...new Set(
            fetchedData.map(item => (language === 'fr' && item.reportType_fr ? item.reportType_fr : item.reportType))
          ),
        ];
        setReportTypes(uniqueTypes);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchDashboardData();
  }, [language]);

  useEffect(() => {
    let updatedData = [...data];

    if (filterType !== 'all') {
      updatedData = updatedData.filter(item => (language === 'fr' && item.reportType_fr ? item.reportType_fr : item.reportType) === filterType);
    }

    updatedData.sort((a, b) => {
      if (sortOrder === 'asc') {
        return new Date(a.publicationDate) - new Date(b.publicationDate);
      } else {
        return new Date(b.publicationDate) - new Date(a.publicationDate);
      }
    });

    setFilteredData(updatedData);
  }, [filterType, sortOrder, data, language]);

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  return (
    <div className="browse-page container">
      <section className='section'>
        <div className="filter-sort">
          <div>
            <label htmlFor="reportTypeFilter">{language === 'fr' ? 'Filtrer par type de rapport:' : 'Filter by Report Type:'}</label>
            <select
              id="reportTypeFilter"
              value={filterType}
              onChange={handleFilterChange}
            >
              <option value="all">{language === 'fr' ? 'Tous' : 'All'}</option>
              {reportTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="sortOrder">{language === 'fr' ? 'Trier par date:' : 'Sort by Date:'}</label>
            <select
              id="sortOrder"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="asc">{language === 'fr' ? 'Plus ancien en premier' : 'Oldest First'}</option>
              <option value="desc">{language === 'fr' ? 'Le plus récent en premier' : 'Newest First'}</option>
            </select>
          </div>
        </div>
        
        <div className="card-grid">
          {filteredData.map((item) => (
            <DashboardCard
              key={item.reportId}
              reportId={item.reportId}
              name={language === 'fr' && item.name_fr ? item.name_fr : item.name}
              nameEng={item.name} 
              thumbnail={language === 'fr' && item.thumbnail_fr ? item.thumbnail_fr : item.thumbnail}
              publicationDate={item.publicationDate}
              description={language === 'fr' && item.description_fr ? item.description_fr : item.description}
              reportType={language === 'fr' && item.reportType_fr ? item.reportType_fr : item.reportType}
              isPopular={item.isPopular}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Browse;
