import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import SideNav from './components/SideNav/SideNav';
import Nav from './components/Nav/Nav';
import Dashboard from './components/Dashboard/Dashboard';
import Browse from './pages/Browse/Browse';
import LoginModal from './components/Authentication/LoginModal';
import RegisterModal from './components/Authentication/RegisterModal';
import AccessRequests from './pages/AccessRequests/AccessRequests';
import { useAuth } from './context/AuthContext'; 
import Invite from './pages/Invite/Invite';
import Saved from './pages/Saved/Saved';
import ViewCodes from './pages/ViewCodes/ViewCodes';
import ReportAnalytics from './components/ReportAnalytics/ReportAnalytics';
import Billbot from './components/Billbot/Billbot';
import Settings from './pages/Settings/Settings';
import FAQs from './pages/FAQs/FAQs';
import Feedback from './pages/Feedback/Feedback';
import Upload from './pages/Upload/Upload';
import MobileNav from './components/MobileNav/MobileNav';
import SearchResults from './pages/SearchResults/SearchResults';

function App() {
  const { isAdmin, userLoggedIn, currentUserStatus } = useAuth(); 

  return (
    <Router>
      <div className="app-container">
        {/* <Billbot /> */}
        <Nav />
        <SideNav />
        <MobileNav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/search" element={<SearchResults />} /> 
          {/* ADMIN */}
          {userLoggedIn && isAdmin ? ( 
            <Route path="/analytics" element={<ReportAnalytics />} />
          ) : (
            <Route path="/analytics" element={<Navigate to="/" replace />} /> 
          )}
          {userLoggedIn && isAdmin ? ( 
            <Route path="/upload" element={<Upload />} />
          ) : (
            <Route path="/upload" element={<Navigate to="/" replace />} /> 
          )}
          {userLoggedIn && isAdmin ? ( 
            <Route path="/access" element={<AccessRequests />} />
          ) : (
            <Route path="/access" element={<Navigate to="/" replace />} /> 
          )}
          {userLoggedIn && isAdmin ? ( 
            <Route path="/invite" element={<Invite />} />
          ) : (
            <Route path="/invite" element={<Navigate to="/" replace />} />
          )}
          {userLoggedIn && isAdmin ? ( 
            <Route path="/viewcodes" element={<ViewCodes />} />
          ) : (
            <Route path="/viewcodes" element={<Navigate to="/" replace />} /> 
          )}
          {userLoggedIn &&  currentUserStatus.isVerified ? ( 
            <Route path="/dashboard/:name" element={<Dashboard />} />
          ) : (
            <Route path="/dashboard/:name" element={<Navigate to="/login" replace />} /> 
          )}
          {userLoggedIn &&  currentUserStatus.isVerified ? ( 
            <Route path="/settings" element={<Settings />} />
          ) : (
            <Route path="/settings" element={<Navigate to="/login" replace />} /> 
          )}
          {/* OTHER ROUTES */}
          <Route path="/login" element={<LoginModal />} />
          <Route path="/register" element={<RegisterModal />} />
          <Route path="/browse" element={<Browse />} />
          <Route path="/saved" element={<Saved />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
