import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SideNav.css';
import { useAuth } from '../../context/AuthContext';
import { doSignOut } from '../../auth';
import { LanguageContext } from '../../context/LanguageContext'; // Import LanguageContext
import analytics from '../../assets/menu/analytics.png';
import upload from '../../assets/menu/cloud-computing.png';
import request from '../../assets/menu/interview.png';
import code from '../../assets/menu/password (1).png';
import home from '../../assets/menu/home.png';
import browse from '../../assets/menu/find.png';
import save from '../../assets/menu/save-instagram.png';
import faq from '../../assets/menu/help.png';
import setting from '../../assets/menu/settings.png';
import feedback from '../../assets/menu/feedback.png';

const SideNav = () => {
  const { currentUser, isAdmin } = useAuth();
  const { language } = useContext(LanguageContext); // Use LanguageContext
  const navigate = useNavigate();

  const handleLogout = () => {
    doSignOut()
      .then(() => {
        navigate('/');
      })
      .catch(error => {
        console.error('Error signing out:', error);
      });
  };

  return (
    <nav className="sidenav">
      <div className="sidenav-top">
        {currentUser && isAdmin && (
          <section className="sidenav-section">
            <h2>{language === 'en' ? 'Admin' : 'Administrateur'}</h2>
            <ul>
              <Link to="/analytics">
                <li>
                  <img src={analytics} alt="Analytics" />
                  <span>{language === 'en' ? 'Analytics' : 'Analytique'}</span>
                </li>
              </Link>
              <Link to="/upload">
                <li>
                  <img src={upload} alt="Upload Dashboard" />
                  <span>{language === 'en' ? 'Upload Dashboard' : 'Tableau de téléchargement'}</span>
                </li>
              </Link>
              <Link to="/access">
                <li>
                  <img src={request} alt="Access Requests" />
                  <span>{language === 'en' ? 'Access Requests' : 'Demandes d\'accès'}</span>
                </li>
              </Link>
              <Link to="/invite">
                <li>
                  <img src={code} alt="Generate Invite Codes" />
                  <span>{language === 'en' ? 'Generate Invite Codes' : 'Générer des codes d\'invitation'}</span>
                </li>
              </Link>
              <Link to="/viewcodes">
                <li>
                  <img src={code} alt="Manage Invite Codes" />
                  <span>{language === 'en' ? 'Manage Invite Codes' : 'Gérer les codes d\'invitation'}</span>
                </li>
              </Link>
            </ul>
          </section>
        )}

        <section className="sidenav-section">
          <h2>{language === 'en' ? 'Reports' : 'Rapports'}</h2>
          <ul>
            <Link to="/">
              <li>
                <img src={home} alt="Home" />
                <span>{language === 'en' ? 'Home' : 'Accueil'}</span>
              </li>
            </Link>
            <Link to="/browse">
              <li>
                <img src={browse} alt="Browse Reports" />
                <span>{language === 'en' ? 'Browse Reports' : 'Parcourir les rapports'}</span>
              </li>
            </Link>
            <Link to="/saved">
              <li>
                <img src={save} alt="Saved Reports" />
                <span>{language === 'en' ? 'Saved Reports' : 'Rapports enregistrés'}</span>
              </li>
            </Link>
          </ul>
        </section>

        <section className="sidenav-section">
          <h2>{language === 'en' ? 'Account and Support' : 'Compte et Support'}</h2>
          <ul>
            <Link to="/settings">
              <li>
                <img src={setting} alt="Settings" />
                <span>{language === 'en' ? 'Settings' : 'Paramètres'}</span>
              </li>
            </Link>
            <Link to="/faqs">
              <li>
                <img src={faq} alt="FAQs" />
                <span>{language === 'en' ? 'FAQs' : 'FAQs'}</span>
              </li>
            </Link>
            <Link to="/feedback">
              <li>
                <img src={feedback} alt="Feedback" />
                <span>{language === 'en' ? 'Feedback' : 'Retour'}</span>
              </li>
            </Link>
          </ul>
        </section>
      </div>

      <div className="sidenav-bottom">
        {currentUser ? (
          <button onClick={handleLogout} className="logout-button primary-button">
            {language === 'en' ? 'Logout' : 'Se déconnecter'}
          </button>
        ) : (
          <Link to="/login">
            <button className="login-button primary-button">
              {language === 'en' ? 'Login' : 'Connexion'}
            </button>
          </Link>
        )}
      </div>
    </nav>
  );
};

export default SideNav;
