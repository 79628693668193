import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import { LanguageContext } from '../../context/LanguageContext';

const SearchResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [filterType, setFilterType] = useState('all');
  const [sortOrder, setSortOrder] = useState('asc');
  const [reportTypes, setReportTypes] = useState([]);
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('q') || '';
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const fetchData = async () => {
      const dashboardCollectionRef = collection(db, 'dashboard_data');
      const snapshot = await getDocs(dashboardCollectionRef);
      const fetchedData = snapshot.docs.map((doc) => ({
        reportId: doc.id,
        ...doc.data(),
      }));

      const uniqueTypes = [
        ...new Set(
          fetchedData.map((item) => (language === 'fr' && item.reportType_fr ? item.reportType_fr : item.reportType))
        ),
      ];
      setReportTypes(uniqueTypes);

      let filteredData = fetchedData.filter((item) =>
        (language === 'fr' && item.name_fr ? item.name_fr : item.name).toLowerCase().includes(searchQuery.toLowerCase())
      );

      if (filterType !== 'all') {
        filteredData = filteredData.filter((item) => (language === 'fr' && item.reportType_fr ? item.reportType_fr : item.reportType) === filterType);
      }

      filteredData = filteredData.sort((a, b) => {
        const aDate = new Date(a.publicationDate);
        const bDate = new Date(b.publicationDate);
        return sortOrder === 'asc' ? aDate - bDate : bDate - aDate;
      });

      setSearchResults(filteredData);
    };

    fetchData();
  }, [searchQuery, filterType, sortOrder, language]);

  return (
    <div className="search-results container">
      <button onClick={() => navigate('/')} className="back-button">
        <span>←</span> {language === 'fr' ? 'Retour à l\'accueil' : 'Back to Home'}
      </button>
      <h2>{language === 'fr' ? `Résultats de recherche pour "${searchQuery}"` : `Search Results for "${searchQuery}"`}</h2>
      <section className="section">
        <div className="card-grid">
          {searchResults.length > 0 ? (
            searchResults.map((item) => (
              <DashboardCard
                key={item.reportId}
                reportId={item.reportId}
                name={language === 'fr' && item.name_fr ? item.name_fr : item.name}
                nameEng={item.name}
                thumbnail={language === 'fr' && item.thumbnail_fr ? item.thumbnail_fr : item.thumbnail}
                publicationDate={item.publicationDate}
                description={language === 'fr' && item.description_fr ? item.description_fr : item.description}
                reportType={language === 'fr' && item.reportType_fr ? item.reportType_fr : item.reportType}
                isPopular={item.isPopular}
              />
            ))
          ) : (
            <p>{language === 'fr' ? `Aucun résultat trouvé pour "${searchQuery}".` : `No results found for "${searchQuery}".`}</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default SearchResults;
