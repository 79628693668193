import React, { useContext, useState, useEffect } from "react";
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState(null);
    const [currentUserStatus, setCurrentUserStatus] = useState(null);
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [isEmailUser, setIsEmailUser] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false); // State for admin role
    const [isMarketing, setIsMarketing] = useState(false); // State for marketing role
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, initializeUser);
        return unsubscribe;
    }, []);

    async function initializeUser(user) {
        if (user) {
            setCurrentUser({ ...user });

            const isEmail = user.providerData.some(
                (provider) => provider.providerId === "password"
            );
            setIsEmailUser(isEmail);

            try {
                const q = query(collection(db, 'commb_users'), where('uid', '==', user.uid));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    const userDocSnapshot = querySnapshot.docs[0];
                    const userData = userDocSnapshot.data();
                    setCurrentUserStatus(userData);

                    // Set role states based on user role from Firestore
                    if (userData.role === 'admin') {
                        setIsAdmin(true);
                        setIsMarketing(false); // Reset marketing state if admin
                    } else if (userData.role === 'marketing') {
                        setIsMarketing(true);
                        setIsAdmin(false); // Reset admin state if marketing
                    } else {
                        setIsAdmin(false);
                        setIsMarketing(false);
                    }
                } else {
                    console.log('User document not found.');
                }
            } catch (error) {
                console.error('Error fetching user document from commb_users:', error);
            }

            setUserLoggedIn(true);
        } else {
            // Reset all states on logout
            setCurrentUser(null);
            setUserLoggedIn(false);
            setIsAdmin(false);
            setIsMarketing(false); // Reset marketing state on logout
        }

        setLoading(false);
    }

    const value = {
        userLoggedIn,
        isEmailUser,
        currentUser,
        setCurrentUser,
        currentUserStatus,
        isAdmin, // Include isAdmin in the context value
        isMarketing, // Include isMarketing in the context value
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
}
