import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './ReportAnalytics.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

const ReportAnalytics = () => {
  const [reportAnalytics, setReportAnalytics] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const dashboardCollectionRef = collection(db, 'dashboard_data');
        const dashboardSnapshot = await getDocs(dashboardCollectionRef);
        const fetchedDashboardData = dashboardSnapshot.docs.map(doc => ({
          reportId: doc.id,
          ...doc.data()
        }));
        const usersRef = collection(db, 'commb_users');
        const usersSnapshot = await getDocs(usersRef);

        const analyticsData = [];

        for (const userDoc of usersSnapshot.docs) {
          const userId = userDoc.id;
          const activityLogRef = collection(userDoc.ref, 'activity_log');
          const activityLogSnapshot = await getDocs(activityLogRef);

          activityLogSnapshot.forEach(activityDoc => {
            const activityData = activityDoc.data();
            const { type, dashboardId } = activityData;

            let reportAnalyticsItem = analyticsData.find(item => item.reportId === dashboardId);
            if (!reportAnalyticsItem) {
              reportAnalyticsItem = {
                reportId: dashboardId,
                savesCount: 0,
                viewsCount: 0,
                uniqueUsers: new Set(),
              };
              analyticsData.push(reportAnalyticsItem);
            }

            if (type === 'save') {
              reportAnalyticsItem.savesCount++;
            } else if (type === 'view') {
              reportAnalyticsItem.viewsCount++;
              reportAnalyticsItem.uniqueUsers.add(userId);
            }
          });
        }

        analyticsData.forEach(item => {
          item.uniqueUsersCount = item.uniqueUsers.size;
          delete item.uniqueUsers; 

          const dashboardItem = fetchedDashboardData.find(dashboard => dashboard.reportId === item.reportId);
          if (dashboardItem) {
            item.reportName = dashboardItem.name;
          } else {
            item.reportName = 'Unknown'; 
          }
        });

        setReportAnalytics(analyticsData);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
      }
    };

    fetchAnalyticsData();
  }, []);

  const handleSort = key => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedAnalytics = [...reportAnalytics].sort((a, b) => {
    const key = sortConfig.key;
    if (key === null) return 0;

    const directionFactor = sortConfig.direction === 'asc' ? 1 : -1;

    if (key === 'reportName') {
      return a[key].localeCompare(b[key]) * directionFactor;
    } else {
      return (a[key] - b[key]) * directionFactor;
    }
  });

  return (
    <div className="analytics container">
      <h2>Report Analytics</h2>
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('reportId')}>
              Report ID{' '}
              {sortConfig.key === 'reportId' && (
                <FontAwesomeIcon icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown} />
              )}
              {sortConfig.key !== 'reportId' && <FontAwesomeIcon icon={faSort} />}
            </th>
            <th onClick={() => handleSort('reportName')}>
              Report Name{' '}
              {sortConfig.key === 'reportName' && (
                <FontAwesomeIcon icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown} />
              )}
              {sortConfig.key !== 'reportName' && <FontAwesomeIcon icon={faSort} />}
            </th>
            <th onClick={() => handleSort('savesCount')}>
              Save Counts{' '}
              {sortConfig.key === 'savesCount' && (
                <FontAwesomeIcon icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown} />
              )}
              {sortConfig.key !== 'savesCount' && <FontAwesomeIcon icon={faSort} />}
            </th>
            <th onClick={() => handleSort('viewsCount')}>
              View Counts{' '}
              {sortConfig.key === 'viewsCount' && (
                <FontAwesomeIcon icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown} />
              )}
              {sortConfig.key !== 'viewsCount' && <FontAwesomeIcon icon={faSort} />}
            </th>
            <th onClick={() => handleSort('uniqueUsersCount')}>
              Viewed by # of Users{' '}
              {sortConfig.key === 'uniqueUsersCount' && (
                <FontAwesomeIcon icon={sortConfig.direction === 'asc' ? faSortUp : faSortDown} />
              )}
              {sortConfig.key !== 'uniqueUsersCount' && <FontAwesomeIcon icon={faSort} />}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedAnalytics.map(report => (
            <tr key={report.reportId}>
              <td>{report.reportId}</td>
              <td>{report.reportName}</td>
              <td>{report.savesCount}</td>
              <td>{report.viewsCount}</td>
              <td>{report.uniqueUsersCount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportAnalytics;
