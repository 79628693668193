import React, { useState } from 'react';
import './Invite.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faCopy } from '@fortawesome/free-solid-svg-icons';
import { generateRandomCode } from '../../utils/codeGenerator';
import { useAuth } from '../../context/AuthContext'
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase'

const Invite = () => {
  const { currentUserStatus } = useAuth();
  const [generatedCodes, setGeneratedCodes] = useState([]);
  const [accordionState, setAccordionState] = useState('');
  const [maxUses, setMaxUses] = useState(1);
  const [expirationDays, setExpirationDays] = useState(0);
  const [customCode, setCustomCode] = useState('');
  const [numCodes, setNumCodes] = useState(5);

  const addCodeToFirestore = async (code, maxUses, expirationDays, currentUserStatus) => {
    const userDetails = {
      firstName: currentUserStatus.firstName,
      lastName: currentUserStatus.lastName,
    };

    const docData = {
      code,
      uses: 0,
      maxUses,
      expirationDays,
      createdAt: serverTimestamp(),
      createdBy: userDetails,
    };

    try {
      const docRef = await addDoc(collection(db, 'invite_codes'), docData);
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  const handleGenerateRandomCode = async () => {
    setGeneratedCodes([]);

    const randomCode = generateRandomCode(maxUses, expirationDays);
    setGeneratedCodes([randomCode]);

    await addCodeToFirestore(randomCode.code, maxUses, expirationDays, currentUserStatus);
  };

  const handleBatchGenerateCodes = async () => {
    setGeneratedCodes([]);

    const batchCodes = [];
    for (let i = 0; i < numCodes; i++) {
      const randomCode = generateRandomCode(1, 0); // Set maxUses and expirationDays for batch codes
      batchCodes.push(randomCode);

      await addCodeToFirestore(randomCode.code, 1, 0, currentUserStatus);
    }
    setGeneratedCodes(batchCodes);
  };

  const handleCustomCodeGeneration = async () => {
    if (!customCode) {
      alert('Custom code cannot be empty.');
      return;
    }

    setGeneratedCodes([]);

    const codeProperties = {
      code: customCode,
      numUses: 1,
      expirationDays: expirationDays || 0,
      maxUses: maxUses || 1,
    };
    setGeneratedCodes([codeProperties]);

    await addCodeToFirestore(customCode, codeProperties.maxUses, codeProperties.expirationDays, currentUserStatus);
  };

  const copyCodeToClipboard = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      alert('Code copied to clipboard');
    }).catch((error) => {
      console.error('Failed to copy: ', error);
    });
  };

  const copyAllCodesToClipboard = () => {
    const allCodes = generatedCodes.map(code => code.code).join('\n');
    navigator.clipboard.writeText(allCodes).then(() => {
      alert('All codes copied to clipboard');
    }).catch((error) => {
      console.error('Failed to copy all codes: ', error);
    });
  };

  const toggleAccordion = (section) => {
    if (accordionState === section) {
      setAccordionState(''); // Close accordion if clicking again on the active section
    } else {
      setAccordionState(section); // Open accordion for the clicked section
    }
  };

  return (
    <div className='container invite'>
      <h2>Invite Code Generator</h2>

      <div className='accordion'>
        {/* Random Code Accordion */}
        <button className={`accordion-btn primary-button ${accordionState === 'random' ? 'active' : ''}`} onClick={() => toggleAccordion('random')}>
          Generate Random Multi-Use Code <FontAwesomeIcon icon={faChevronDown} />
        </button>
        {accordionState === 'random' && (
          <div className='panel'>
            <div className='form'>
              <label htmlFor='maxUsesSelect'>Max Uses:</label>
              <select id='maxUsesSelect' defaultValue='1' onChange={(e) => setMaxUses(parseInt(e.target.value))}>
                <option value='1'>1</option>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
              <label htmlFor='expirationDaysSelect'>Expires After (days):</label>
              <select id='expirationDaysSelect' defaultValue='0' onChange={(e) => setExpirationDays(parseInt(e.target.value))}>
                <option value='0'>Never</option>
                <option value='1'>1</option>
                <option value='7'>7</option>
                <option value='30'>30</option>
              </select>
              <button className='primary-button' onClick={handleGenerateRandomCode}>Generate Code</button>
            </div>
          </div>
        )}

        {/* Batch Generate Accordion */}
        <button className={`accordion-btn primary-button ${accordionState === 'batch' ? 'active' : ''}`} onClick={() => toggleAccordion('batch')}>
          Batch Generate Single Use Codes <FontAwesomeIcon icon={faChevronDown} />
        </button>
        {accordionState === 'batch' && (
          <div className='panel'>
            <div className='form'>
            <label htmlFor='numCodesInput'>Number of Codes:</label>
              <input type='number' id='numCodesInput' value={numCodes} onChange={(e) => setNumCodes(parseInt(e.target.value))} />
              <button className='primary-button' onClick={handleBatchGenerateCodes}>Generate Codes</button>
            </div>
          </div>
        )}

        {/* Custom Code Accordion */}
        <button className={`accordion-btn primary-button ${accordionState === 'custom' ? 'active' : ''}`} onClick={() => toggleAccordion('custom')}>
          Generate Custom Code <FontAwesomeIcon icon={faChevronDown} />
        </button>
        {accordionState === 'custom' && (
          <div className='panel'>
            <div className='form'>
              <label htmlFor='customCodeInput'>Enter Custom Code:</label>
              <input type='text' id='customCodeInput' value={customCode} onChange={(e) => setCustomCode(e.target.value)} required />
              <label htmlFor='customMaxUsesSelect'>Max Uses:</label>
              <select id='customMaxUsesSelect' defaultValue='1' onChange={(e) => setMaxUses(parseInt(e.target.value))}>
                <option value='1'>1</option>
                <option value='5'>5</option>
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
                <option value='100'>100</option>
              </select>
              <label htmlFor='customExpirationDaysSelect'>Expires After (days):</label>
              <select id='customExpirationDaysSelect' defaultValue='0' onChange={(e) => setExpirationDays(parseInt(e.target.value))}>
                <option value='0'>Never</option>
                <option value='1'>1</option>
                <option value='7'>7</option>
                <option value='30'>30</option>
              </select>
              <button className='primary-button' onClick={handleCustomCodeGeneration}>Generate Code</button>
            </div>
          </div>
        )}
      </div>

      <div className='generated-codes'>
        {generatedCodes.length > 0 && (
          <div>
            {generatedCodes.length > 1 ? (
              <div>
                <button className='copy-all-btn'  primary-buttononClick={copyAllCodesToClipboard}>
                  Copy Codes <FontAwesomeIcon icon={faCopy} />
                </button>
                <ul>
                  {generatedCodes.map((code, index) => (
                    <li key={index}>
                      <span className='code'>{code.code}</span>
                      <span className='info'>
                        Uses: {code.maxUses} | Expires in: {code.expirationDays} days
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <ul>
                {generatedCodes.map((code, index) => (
                  <li key={index}>
                    <span className='code'>{code.code}</span>
                    <span className='info'>
                      Uses: {code.maxUses} | Expires in: {code.expirationDays} days
                    </span>
                    <button className='copy-btn primary-button' onClick={() => copyCodeToClipboard(code.code)}>
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Invite;
