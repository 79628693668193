import React, { useState, useEffect, useContext } from 'react';
import './Home.css';
import { useNavigate } from 'react-router-dom';
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { LanguageContext } from '../../context/LanguageContext';
import logo from '../../assets/brand/Commb 360_final RGB_full white.png'

const Home = () => {
  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const { isAdmin, isMarketing } = useAuth();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'dashboard_data'));
        const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setDashboardData(data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchData();
  }, []);

  const filteredDashboardData = () => {
    if (isAdmin) {
      return dashboardData;
    } else if (isMarketing) {
      return dashboardData.filter(item => item.status === 'published' || item.status === 'pending');
    } else {
      return dashboardData.filter(item => item.status === 'published');
    }
  };

  const handleBannerBrowseNav = () => {
    navigate(`/browse`);
  };

  return (
    <div className="home container">
      <div className="banner-box">
        <div className="banner-content">
          <div>
            <h1>{language === 'en' ? 'Driving innovation in out-of-home with powerful analytics' : 'L\'innovation en affichage, propulsée par l\'analytique'}</h1>
            <img src={logo} alt="" />
          </div>

        </div>
      </div>

      <section className="section">
        <h2>{language === 'en' ? 'Recently Published Dashboard' : 'Publications récentes'}</h2>
        <div className="card-grid">
          {filteredDashboardData().map((item) => (
            <DashboardCard
              reportId={item.reportId}
              name={language === 'fr' && item.name_fr ? item.name_fr : item.name}
              nameEng={item.name} 
              thumbnail={language === 'fr' && item.thumbnail_fr ? item.thumbnail_fr : item.thumbnail}
              publicationDate={item.publicationDate}
              description={language === 'fr' && item.description_fr ? item.description_fr : item.description}
              reportType={language === 'fr' && item.reportType_fr ? item.reportType_fr : item.reportType}
              isPopular={item.isPopular}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Home;
