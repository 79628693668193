import React, { useEffect, useState, useContext } from 'react';
import DashboardCard from '../../components/DashboardCard/DashboardCard';
import { useAuth } from '../../context/AuthContext';
import { db } from '../../firebase';
import { collection, doc, getDocs } from 'firebase/firestore';
import { LanguageContext } from '../../context/LanguageContext';

const Saved = () => {
  const { currentUserStatus } = useAuth();
  const { language } = useContext(LanguageContext);
  const [savedReports, setSavedReports] = useState([]);
  const [filterType, setFilterType] = useState('all');
  const [sortOrder, setSortOrder] = useState('asc');
  const [reportTypes, setReportTypes] = useState([]);
  const [dashboardData, setDashboardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (currentUserStatus && currentUserStatus.isVerified) {
        try {
          const userId = currentUserStatus.uid;
          const userRef = doc(db, 'commb_users', userId);
          const savedReportsCollectionRef = collection(userRef, 'saved_reports');
          const savedReportsSnapshot = await getDocs(savedReportsCollectionRef);

          const savedReportsData = savedReportsSnapshot.docs.map(doc => ({
            id: doc.id,
            reportId: doc.id 
          }));

          const dashboardCollectionRef = collection(db, 'dashboard_data');
          const dashboardSnapshot = await getDocs(dashboardCollectionRef);
          const fetchedDashboardData = dashboardSnapshot.docs.map(doc => ({
            reportId: doc.id,
            ...doc.data()
          }));

          setDashboardData(fetchedDashboardData);

          let filteredSavedReports = savedReportsData;
          if (filterType !== 'all') {
            filteredSavedReports = savedReportsData.filter(item => {
              const matchedDashboardItem = fetchedDashboardData.find(dashboardItem => dashboardItem.reportId === item.reportId);
              return matchedDashboardItem && (language === 'fr' && matchedDashboardItem.reportType_fr ? matchedDashboardItem.reportType_fr : matchedDashboardItem.reportType) === filterType;
            });
          }

          const sortedSavedReports = filteredSavedReports.sort((a, b) => {
            const aDate = fetchedDashboardData.find(item => item.reportId === a.reportId)?.publicationDate || new Date();
            const bDate = fetchedDashboardData.find(item => item.reportId === b.reportId)?.publicationDate || new Date();
            return sortOrder === 'asc' ? new Date(aDate) - new Date(bDate) : new Date(bDate) - new Date(aDate);
          });

          setSavedReports(sortedSavedReports);

          const uniqueTypes = [...new Set(fetchedDashboardData.map(item => (language === 'fr' && item.reportType_fr ? item.reportType_fr : item.reportType)))];
          setReportTypes(uniqueTypes);

        } catch (error) {
          console.error('Error fetching saved reports and dashboard data:', error);
        }
      }
    };

    fetchData();
  }, [currentUserStatus, filterType, sortOrder, language]);

  return (
    <div className="saved-reports container">
      <section className='section'>
        <div className="filter-sort">
          <div>
            <label htmlFor="reportTypeFilter">{language === 'fr' ? 'Filtrer par type de rapport:' : 'Filter by Report Type:'}</label>
            <select
              id="reportTypeFilter"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="all">{language === 'fr' ? 'Tous' : 'All'}</option>
              {reportTypes.map(type => (
                <option key={type} value={type}>{type}</option>
              ))}
            </select>
          </div>
          <div>
            <label htmlFor="sortOrder">{language === 'fr' ? 'Trier par date:' : 'Sort by Date:'}</label>
            <select
              id="sortOrder"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="asc">{language === 'fr' ? 'Plus ancien en premier' : 'Oldest First'}</option>
              <option value="desc">{language === 'fr' ? 'Le plus récent en premier' : 'Newest First'}</option>
            </select>
          </div>
        </div>
        <div className="card-grid">
          {savedReports.length > 0 ? (
            savedReports.map(report => {
              const matchedDashboardItem = dashboardData.find(item => item.reportId === report.reportId);
              if (matchedDashboardItem) {
                return (
                  <DashboardCard
                    key={matchedDashboardItem.reportId}
                    reportId={matchedDashboardItem.reportId}
                    name={language === 'fr' && matchedDashboardItem.name_fr ? matchedDashboardItem.name_fr : matchedDashboardItem.name}
                    nameEng={matchedDashboardItem.name}
                    thumbnail={language === 'fr' && matchedDashboardItem.thumbnail_fr ? matchedDashboardItem.thumbnail_fr : matchedDashboardItem.thumbnail}
                    publicationDate={matchedDashboardItem.publicationDate}
                    description={language === 'fr' && matchedDashboardItem.description_fr ? matchedDashboardItem.description_fr : matchedDashboardItem.description}
                    reportType={language === 'fr' && matchedDashboardItem.reportType_fr ? matchedDashboardItem.reportType_fr : matchedDashboardItem.reportType}
                    isPopular={matchedDashboardItem.isPopular} 
                  />
                );
              }
              return null;
            })
          ) : (
            <p>{language === 'fr' ? 'Aucun rapport enregistré trouvé.' : 'No saved reports found.'}</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Saved;
