import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { doCreateUserWithEmailAndPassword } from '../../auth';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import { collection, serverTimestamp, updateDoc, doc, query, where, getDocs, setDoc } from 'firebase/firestore';
import './RegisterModal.css';

const RegisterModal = () => {
    const { currentUserStatus } = useAuth();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [company, setCompany] = useState('');
    const [inviteCode, setInviteCode] = useState('');
    const [isInviteCodeValid, setIsInviteCodeValid] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [ipAddress, setIpAddress] = useState(null);
    const [registrationCompleted, setRegistrationCompleted] = useState(false);
    const [countdown, setCountdown] = useState(3);
    const [accessRequested, setAccessRequested] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showAccessRequestedMessage, setShowAccessRequestedMessage] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchIpAddress = async () => {
            try {
                const response = await axios.get('https://api.ipify.org?format=json');
                setIpAddress(response.data.ip);
            } catch (error) {
                console.error('Error fetching IP address:', error);
            }
        };

        fetchIpAddress();
    }, []);

    useEffect(() => {
        let timer;
        if (registrationCompleted && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            navigate('/');
        }

        return () => clearInterval(timer);
    }, [registrationCompleted, countdown, navigate]);

    const onSubmit = async (e) => {
        e.preventDefault();

        const validationResponse = await validateInviteCode(inviteCode);
        setIsInviteCodeValid(validationResponse.valid);
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match');
            return;
        }

        if (!isRegistering) {
            setIsRegistering(true);
            try {
                const userCredential = await doCreateUserWithEmailAndPassword(email, password);
                const { uid, email: userEmail } = userCredential.user;

                let role = 'user';
                if (validationResponse.data) {
                    if (validationResponse.data.role === 'admin') {
                        role = 'admin';
                    } else if (validationResponse.data.role === 'marketing') {
                        role = 'marketing';
                    }
                }

                const verifiedStatus = validationResponse.valid || role === 'admin' || role === 'marketing';
                const commbUsersRef = collection(db, 'commb_users');

                await setDoc(doc(commbUsersRef, uid), {
                    uid: uid,
                    firstName: firstName.trim(),
                    lastName: lastName.trim(),
                    email: userEmail,
                    company: company.trim(),
                    isVerified: verifiedStatus,
                    role: role,
                    accessRequested: false,
                    ipAddress: ipAddress,
                    createdAt: serverTimestamp(),
                    inviteCode: inviteCode.trim() || null
                });

                if (validationResponse.valid) {
                    const querySnapshot = await getDocs(query(collection(db, 'invite_codes'), where('code', '==', inviteCode)));
                    if (!querySnapshot.empty) {
                        const inviteDoc = querySnapshot.docs[0];
                        await updateDoc(doc(db, 'invite_codes', inviteDoc.id), {
                            uses: inviteDoc.data().uses + 1
                        });
                    }
                }

                setFirstName('');
                setLastName('');
                setEmail('');
                setPassword('');
                setConfirmPassword('');
                setCompany('');
                setInviteCode('');
                setErrorMessage('');
                setRegistrationCompleted(true);
                setShowSuccessMessage(true);

            } catch (error) {
                console.error('Error signing up:', error.message);
                if (error.code === 'auth/email-already-in-use') {
                    setErrorMessage('Email already in use');
                } else {
                    setErrorMessage('Error signing up. Please try again later.');
                }
            } finally {
                setIsRegistering(false);
            }
        }
    };

    const handleRequestAccess = async () => {
        setAccessRequested(true);
        setShowAccessRequestedMessage(true);
        setShowSuccessMessage(false);

        let countdownTimer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 1) {
                    clearInterval(countdownTimer);
                    navigate('/');
                }
                return prevCountdown - 1;
            });
        }, 1000);

        const usersRef = collection(db, 'commb_users');
        const q = query(usersRef, where('uid', '==', currentUserStatus.uid));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach(async (doc) => {
            await updateDoc(doc.ref, {
                accessRequested: true
            });
        });
    };

    const validateInviteCode = async (inviteCode) => {
        if (!inviteCode) {
            return { valid: false };
        }

        const codesCollectionRef = collection(db, 'invite_codes');
        const q = query(codesCollectionRef, where('code', '==', inviteCode.trim()));

        try {
            const querySnapshot = await getDocs(q);

            if (querySnapshot.empty) {
                console.log('Invite code not found');
                return { valid: false, error: 'Invite code not found.' };
            }

            const codeDoc = querySnapshot.docs[0];
            const codeData = codeDoc.data();

            if (codeData.expirationDays !== 0) {
                const expirationDate = new Date(codeData.createdAt.toMillis());
                expirationDate.setDate(expirationDate.getDate() + codeData.expirationDays);

                if (expirationDate < new Date()) {
                    console.log('Invite code has expired');
                    return { valid: false, error: 'Invite code has expired.' };
                }
            }

            if (codeData.uses >= codeData.maxUses) {
                console.log('Invite code has reached its maximum uses');
                return { valid: false, error: 'Invite code has reached its maximum uses.' };
            }

            return { valid: true, data: codeData };
        } catch (error) {
            console.error('Error validating invite code:', error);
            return { valid: false, error: 'Error validating invite code.' };
        }
    };

    return (
        <main className='register container'>
            {!registrationCompleted ? (
                <form onSubmit={onSubmit}>
                    <div>
                        <input
                            disabled={isRegistering}
                            type="text"
                            autoComplete='given-name'
                            required
                            value={firstName}
                            placeholder='First Name'
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            disabled={isRegistering}
                            type="text"
                            autoComplete='family-name'
                            required
                            value={lastName}
                            placeholder='Last Name'
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            disabled={isRegistering}
                            type="text"
                            autoComplete='organization'
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            placeholder='Company'
                            required
                        />
                    </div>

                    <div>
                        <input
                            disabled={isRegistering}
                            type="email"
                            autoComplete='email'
                            required
                            value={email}
                            placeholder='Email'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            disabled={isRegistering}
                            type="password"
                            autoComplete='new-password'
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder='Password'
                        />
                    </div>

                    <div>
                        <input
                            disabled={isRegistering}
                            type="password"
                            autoComplete='off'
                            required
                            value={confirmPassword}
                            placeholder='Confirm Password'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>

                    <div>
                        <input
                            disabled={isRegistering}
                            type="text"
                            value={inviteCode}
                            placeholder='Invite Code (Optional)'
                            onChange={(e) => setInviteCode(e.target.value)}
                        />
                    </div>

                    {errorMessage && (
                        <span>{errorMessage}</span>
                    )}

                    <button
                        type="submit"
                        className='primary-button'
                        disabled={isRegistering}
                    >
                        {isRegistering ? 'Signing Up...' : 'Sign Up'}
                    </button>
                </form>
            ) : (
                <div>
                    {showSuccessMessage && <p>You have successfully registered!</p>}
                    {showAccessRequestedMessage && <p>You have successfully requested access!</p>}
                    {isInviteCodeValid ? (
                        <>
                            {!accessRequested ? (
                                <p>Redirecting to home in {countdown} seconds...</p>
                            ) : (
                                <p>Your request has been sent.</p>
                            )}
                        </>
                    ) : (
                        <>
                            {!accessRequested && !showAccessRequestedMessage && !isInviteCodeValid && (
                                <p>Please request access to continue.</p>
                            )}
                            {!accessRequested && !showAccessRequestedMessage && !isInviteCodeValid && (
                                <button onClick={handleRequestAccess} className='primary-button'>Request Access</button>
                            )}
                            {showAccessRequestedMessage && (
                                <p>You have requested access. Redirecting to home in {countdown} seconds...</p>
                            )}
                        </>
                    )}
                </div>
            )}
            {!registrationCompleted && (
                <div className='form-bot'>
                    Already have an account? {' '}
                    <Link to='/login'>Login</Link>
                </div>
            )}
        </main>
    );
};

export default RegisterModal;
