import React, { useState, useContext } from 'react';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { app } from '../../firebase';
import './Feedback.css';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LanguageContext } from '../../context/LanguageContext'; // Import LanguageContext

const db = getFirestore(app);

const Feedback = () => {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [feedbackData, setFeedbackData] = useState({
    experience: '',
    improvement: '',
    enjoyed: '',
  });

  const { language } = useContext(LanguageContext); // Get the current language
  const navigate = useNavigate();

  const questions = [
    language === 'en' ? 'How would you rate your overall experience using the COMMB Insights App?' : 'Comment évaluez-vous votre expérience globale avec l\'application COMMB Insights ?',
    language === 'en' ? 'What aspects of the COMMB Insights App do you think could be improved?' : 'Quels aspects de l\'application COMMB Insights, selon vous, pourraient être améliorés ?',
    language === 'en' ? 'What did you enjoy most about using the COMMB Insights App?' : 'Qu\'avez-vous le plus apprécié dans l\'utilisation de l\'application COMMB Insights ?',
  ];

  const getClassName = () => {
    switch (questionIndex) {
      case 0:
        return 'one';
      case 1:
        return 'two';
      case 2:
        return 'three';
      default:
        return '';
    }
  };

  const handleScaleClick = (e) => {
    if (e.target.parentNode.querySelector('.active')) {
      e.target.parentNode.querySelector('.active').classList.remove('active');
    }
    e.target.classList.add('active');

    setFeedbackData((prev) => ({
      ...prev,
      experience: e.target.innerHTML,
    }));
  };

  const handleButtonClick = async () => {
    if (questionIndex < 2) {
      setQuestionIndex(questionIndex + 1);
    } else {
      const ipAddress = await fetch('https://api64.ipify.org?format=json')
        .then((response) => response.json())
        .then((data) => data.ip);

      const messageData = {
        experience: feedbackData.experience,
        improvement: feedbackData.improvement,
        enjoyed: feedbackData.enjoyed,
        timestamp: serverTimestamp(),
        ipAddress: ipAddress,
      };

      await addDoc(collection(db, 'web-app-feedback'), messageData);

      setQuestionIndex(questionIndex + 1);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && questionIndex !== 0) {
      handleButtonClick();
      if (questionIndex === 1) {
        setFeedbackData((prev) => ({
          ...prev,
          improvement: '',
        }));
      } else if (questionIndex === 2) {
        setFeedbackData((prev) => ({
          ...prev,
          enjoyed: '',
        }));
      }
    }
  };

  const handleChange = (e) => {
    if (questionIndex === 1) {
      setFeedbackData((prev) => ({
        ...prev,
        improvement: e.target.value,
      }));
    } else if (questionIndex === 2) {
      setFeedbackData((prev) => ({
        ...prev,
        enjoyed: e.target.value,
      }));
    }
  };

  return (
    <div className={`feedback-container container ${getClassName()}`}>
      {questionIndex !== 3 ? (
        <div className="question-container">
          <div className="question">
            {questions[questionIndex]}
            <div className="number">
              {questionIndex + 1}
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
          <div className="answer">
            {questionIndex === 0 ? (
              <ul className="scale">
                {[...Array(11).keys()].map((num) => (
                  <li key={num} className="scale-num" onClick={handleScaleClick}>
                    {num}
                  </li>
                ))}
              </ul>
            ) : (
              <input
                type="text"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={questionIndex === 1 ? feedbackData.improvement : feedbackData.enjoyed}
                placeholder={language === 'en' ? 'Type your answer here...' : 'Tapez votre réponse ici...'}
              />
            )}
          </div>
          <button className='primary-button' onClick={handleButtonClick}>
            {questionIndex === 2 ? (language === 'en' ? 'Submit' : 'Soumettre') : (language === 'en' ? 'Next' : 'Suivant')}
          </button>
        </div>
      ) : (
        <div className="finished">
          <h2>{language === 'en' ? 'Thank you for your feedback!' : 'Merci pour vos commentaires.'}</h2>
          <button onClick={() => navigate('/')}>{language === 'en' ? 'Back to home' : 'Retour à l\'accueil'}</button>
        </div>
      )}
    </div>
  );
};

export default Feedback;
