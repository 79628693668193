import { collection, doc, setDoc, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';

// Log a dashboard view
const logDashboardView = async (userId, dashboardId) => {
  try {
    const userActivityRef = collection(doc(db, 'commb_users', userId), 'activity_log');
    const timestamp = Timestamp.now().toMillis(); // Use milliseconds for unique IDs

    // Create a unique document ID based on timestamp and dashboard ID
    const activityDocRef = doc(userActivityRef, `${timestamp}_${dashboardId}`);

    await setDoc(activityDocRef, {
      type: 'view',
      dashboardId: dashboardId,
      timestamp: timestamp
    });

  } catch (error) {
    console.error('Error logging dashboard view:', error);
  }
};

// Log a dashboard save
const logDashboardSave = async (userId, dashboardId) => {
  try {
    const userActivityRef = collection(doc(db, 'commb_users', userId), 'activity_log');
    
    // Query to check if a save log exists
    const q = query(userActivityRef, where('dashboardId', '==', dashboardId), where('type', '==', 'save'));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      console.log('Dashboard save already logged for this user');
      return;
    }
    
    const timestamp = Timestamp.now().toMillis(); // Use milliseconds for unique IDs
    const activityDocRef = doc(userActivityRef, `${timestamp}_${dashboardId}`);

    await setDoc(activityDocRef, {
      type: 'save',
      dashboardId: dashboardId,
      timestamp: timestamp,
    });

  } catch (error) {
    console.error('Error logging dashboard save:', error);
  }
};

export { logDashboardView, logDashboardSave };
