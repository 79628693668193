import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './FAQs.css';
import { LanguageContext } from '../../context/LanguageContext'; // Import LanguageContext

const FAQs = () => {
  const { language } = useContext(LanguageContext); // Get the current language
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const faqItems = [
    {
      question: language === 'en' ? 'What does this app do?' : 'Que fait cette application ?',
      answer: language === 'en' 
        ? 'This app is designed to transform how COMMB shares its research and analytics, making data more accessible and digestible for audiences in the OOH (Out-of-Home) industry.' 
        : 'Cette application est conçue pour transformer la manière dont COMMB partage ses recherches et analyses, rendant les données plus accessibles et compréhensibles pour les audiences de l\'industrie de l\'affichage.'
    },
    {
      question: language === 'en' ? 'What can I do with dashboards?' : 'Que puis-je faire avec les tableaux de bord ?',
      answer: language === 'en' 
        ? 'With the dashboards, you can use dynamic filters to tailor insights to your specific needs, drill down into data, export individual visualizations as PNG, or export the entire dashboard as PDF, Excel, or PNG. The dashboards are easily shareable and can be presented to others seamlessly.' 
        : 'Grâce à leurs filtres dynamiques, nos tableaux de bord vous permettent d\'adapter l\'information à vos besoins, d\'explorer les données en détail, d\'exporter des visualisations en format PNG, et d\'exporter l\'intégralité du tableau en format PDF, Excel ou PNG. De plus, les tableaux peuvent être facilement partagés et présentés.'
    },
    {
      question: language === 'en' ? 'Are the insights always accurate?' : 'L\'information est-elle toujours exacte ?',
      answer: language === 'en' 
        ? 'While these findings provide valuable insights, they should be interpreted in the context of their respective methodologies and inherent limitations. Please note that when the sample size is below 100, the results may not be statistically significant, and conclusions drawn from such small samples may not accurately reflect larger trends. Interpret with caution.' 
        : 'Bien que ces résultats fournissent des informations précieuses, ils doivent être interprétés dans le contexte de leurs méthodologies respectives et des limites inhérentes. Veuillez noter que lorsque la taille de l\'échantillon est inférieure à 100, les résultats peuvent ne pas être statistiquement significatifs et les conclusions tirées de tels échantillons réduits peuvent ne pas refléter fidèlement les tendances plus larges. Interprétez avec prudence.'
    }
  ];

  return (
    <div className="accordion container">
      <div className="wrapper">
        <h2>{language === 'en' ? 'Frequently Asked Questions' : 'Questions fréquentes'}</h2>
        {faqItems.map((item, index) => (
          <div key={index} className={`accordion-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="accordion-title" onClick={() => toggleAccordion(index)}>
              {item.question}
              <FontAwesomeIcon icon={activeIndex === index ? faAngleUp : faAngleDown} className="accordion-icon" />
            </div>
            {activeIndex === index && (
              <div className="accordion-content">
                <p>{item.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;
